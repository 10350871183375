import {
  SettingOutlined,
  MessageOutlined,
  LineChartOutlined,
  TruckOutlined,
  DollarOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { selectTotalUnread } from 'app/pages/ChatPage/slice/selectors';
import queryString from 'querystring';
import React, { useEffect, useMemo } from 'react';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { selectGlobalSlice } from 'app/slice/selectors';
import { hasPermissionAction } from 'utils/helper';

interface SubMenuProps {
  link?: string;
  title?: string;
  key: string;
  label: string;
}

interface MenuDataProps {
  key: string;
  label: string;
  icon?: any;
  link?: string;
  children?: Array<SubMenuProps>;
  permission?: string;
}

const { SubMenu, Item } = Menu;


const MenuLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector(selectGlobalSlice)
  const items: Array<MenuDataProps> = [
    {
      key: 'report',
      label: 'Báo cáo',
      icon: <LineChartOutlined />,
      link: '/report',
      children: [
        {
          key: 'report/overview',
          label: 'Tổng quan',
          link: '/report/overview',
        },
        {
          key: 'report/fullfillment-report',
          label: 'Báo cáo Fulfillment',
          link: '/report/fullfillment-report',
        }
      ]
    },
    {
      key: 'fullfillment-manage',
      label: 'Quản lý Fulfillment',
      icon: <TruckOutlined />,
      link: '/fullfillment-manage',
      children: [
        {
          key: 'fullfillment-manage/operation',
          label: 'Theo dõi vận hành',
          link: '/fullfillment-manage/operation',
        },
      ]
    },
    {
      key: 'settlement-manage',
      label: 'Quản lý đối soát',
      icon: <DollarOutlined />,
      link: '/settlement-manage',
      children: [
        {
          key: 'settlement-manage/manual',
          label: 'Đối soát thủ công',
          link: '/settlement-manage/manual',
        },
      ]
    },
    {
      key: 'chats_setting',
      label: 'Quản lý trò chuyện',
      icon: <MessageOutlined />,
      permission: 'customer_service_chat',
      link: '/chats',
      children: [
        {
          key: 'chats',
          label: 'Trò chuyện',
          link: '/chats',
        },
      ]
    },
    {
      key: 'settings',
      label: 'Cấu hình',
      icon: <SettingOutlined />,
      link: '/settings',
      children: !user?.is_subuser ? [
        {
          key: 'settings/user',
          label: 'Tài khoản',
          link: '/settings/user',
        },
        {
          key: 'settings/smes',
          label: 'Quản lý UpS',
          link: '/settings/smes',
        },
        {
          key: 'settings/sub-user',
          label: 'Phân quyền tài khoản phụ',
          link: '/settings/sub-user',
        }
      ] : [
        {
          key: 'settings/smes',
          label: 'Quản lý UpS',
          link: '/settings/smes',
        }
      ]
    },
  ];

  const getSelectedKeys = () => {
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const matchedKeys = items.reduce((keys, item) => {
      if (item?.children) {
        item?.children.forEach(child => {
          if (location.pathname.includes(child.link || '')) {
            keys.push(child.key);
          }
        });
      } else if (location.pathname.includes(item.link || '')) {
        keys.push(item.key);
      }
      return keys;
    }, [] as string[]);
    return matchedKeys.length ? matchedKeys : [location.pathname];
  };

  return (
    <>
      <Menu
        theme="light"
        mode="inline"
        items={items}
        selectedKeys={getSelectedKeys()}
        defaultOpenKeys={['chat']}
        onClick={(e) => {
          if (e.key == 'customers') {
            window.open(`${process.env.REACT_APP_SME_ENDPOINT}/customer-service/customer-info`, '_blank');
          } else {
            navigate(`/${e.key}`)
          }
        }}
      />
      <span style={{ position: 'fixed', bottom: 10, left: 24 }} >Phiên bản: <strong>{process.env.REACT_APP_VERSION}</strong></span>
    </>
  );
};

export default memo(MenuLayout);
